<template>
  <div class="chat-editor">
    <div class="bubble-menu-chat">
      <div v-if="editor" class="flex gap-1.5 px-2">
        <button
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleBold()
              .run()
          "
        >
          Negrito
        </button>
        <button
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleItalic()
              .run()
          "
        >
          Itálico
        </button>
        <button
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleStrike()
              .run()
          "
        >
          Sublinhado
        </button>
      </div>
    </div>
    <editor-content :editor="editor" class="px-2 py-1" />
  </div>
</template>

<script>
import { Editor, EditorContent, Extension } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import BubbleMenu from '@tiptap/extension-bubble-menu'
export default {
  components: {
    EditorContent //,
    //BubbleMenu
  },

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      editor: null
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.value, false)
    }
  },

  mounted() {
    const self = this
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Escreva uma mensagem...'
        }),
        BubbleMenu.configure({
          element: document.querySelector('.bubble-menu-chat')
        }),
        Extension.create({
          addKeyboardShortcuts() {
            return {
              Enter: () => {
                self.$emit('enter')
                return true
              }
            }
          }
        })
      ],
      parseOptions: {
        preserveWhitespace: 'full'
      },
      editable: true,
      injectCSS: false,
      content: this.value,
      autofocus: true,
      onUpdate: () => {
        let message = this.editor.getHTML()
        // if (message) {
        //   if (
        //     message.match(/<p>/g).length == 1 &&
        //     message.match(/<\/p>/g).length == 1 &&
        //     message.startsWith('<p>') &&
        //     message.endsWith('</p>')
        //   ) {
        //     message = message.substr(3, message.length - 7)
        //   }

        //   message = message.trim()
        //   while (message.startsWith('<br>')) {
        //     message = message.substr(4).trim()
        //   }
        //   while (message.endsWith('<br>')) {
        //     message = message.substr(0, message.length - 4).trim()
        //   }
        // }
        if (message == '<p></p>') {
          message = ''
        }
        // HTML
        this.$emit('input', message)
        // JSON
        // this.$emit('input', this.editor.getJSON())
      }
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.chat-editor {
  @apply relative;
}
.chat-editor .ProseMirror {
  padding: 6px 8px;
  border-radius: 5px;
  background: #eee;
  @apply focus:outline-none;
  overflow: visible;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #999;
  pointer-events: none;
  height: 0;
}
.chat-editor .ProseMirror * {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}
.chat-editor .bubble-menu-chat {
  display: flex;
  background-color: #0d0d0d;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #fff;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.2rem 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
</style>
