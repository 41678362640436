var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-person"},[(_vm.size === 'micro')?_c('div',{staticClass:"flex gap-1"},[(_vm.showAvatar)?_c('fw-avatar',{staticClass:"mr-1.5 flex-shrink-0",attrs:{"size":_vm.size,"user":_vm.person}}):_vm._e(),_c('div',{staticClass:"text-left leading-5 text-xs flex flex-col gap-0"},[_c('div',{staticClass:"flex gap-2"},[_c('v-clamp',{staticClass:"font-medium",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.getPersonName())+" ")]),(_vm.showShortAndFullname)?_c('v-clamp',{staticClass:"text-gray-500 text-xs hidden md:block",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.person.display_name)+" ")]):_vm._e(),(_vm.isActive)?_c('div',{staticClass:"text-xs font-bold text-primary uppercase mx-1"},[_vm._v(" "+_vm._s(_vm.$t('active'))+" ")]):_vm._e()],1),(!_vm.hasSecondLineSlot)?_c('div',{staticClass:"text-gray-500 font-normal flex items-center break-all -mt-1.5 gap-2"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.person.email)+" ")]),(_vm.person.number)?_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v(" "+_vm._s(_vm.person.number)+" ")]):_vm._e()],1):_vm._e(),_vm._t("secondline")],2)],1):(_vm.selectable)?_c('label',{staticClass:"relative select-none",class:[
      {
        'fw-row-checkbox-selectable': _vm.selectable,
        'fw-row-checkbox': !_vm.selectable
      },
      _vm.customClass
    ]},[(_vm.selectable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],staticClass:"opacity-0 absolute",attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":Array.isArray(_vm.currentValue)?_vm._i(_vm.currentValue,null)>-1:(_vm.currentValue)},on:{"change":[function($event){var $$a=_vm.currentValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.currentValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.currentValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.currentValue=$$c}},_vm.selectPerson]}}):_vm._e(),_c('div',{staticClass:"fw-row-checkbox-inside flex w-full gap-1 items-center relative justify-between group bg-transparent",class:{
        'pr-4': !_vm.selectable && _vm.view === 'row',
        'cursor-pointer hover:bg-gray-200 hover:bg-opacity-50': _vm.clickable,
        'rounded-lg': !_vm.noStyle,
        'px-3 py-1.5': _vm.paddingless === false,
        'cursor-not-allowed': _vm.disabled
      }},[(_vm.selectable)?_c('div',{staticClass:"check h-4 w-4 rounded p-1"}):_vm._e(),(_vm.view === 'row')?_c('div',{staticClass:"ml-5"},[_vm._t("leading")],2):_vm._e(),(_vm.showAvatar)?_c('fw-avatar',{staticClass:"mr-1.5 flex-shrink-0",attrs:{"size":_vm.size,"user":_vm.person}}):_vm._e(),_c('div',{staticClass:"text-left leading-5"},[_c('div',{staticClass:"flex gap-2"},[_c('v-clamp',{staticClass:"font-medium",class:{ 'text-sm': _vm.isXs },attrs:{"autoresize":"","max-lines":_vm.nameMaxLines}},[_vm._v(" "+_vm._s(_vm.getPersonName())+" ")]),(_vm.showShortAndFullname)?_c('v-clamp',{staticClass:"text-gray-500 text-xs hidden md:block",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.person.display_name)+" ")]):_vm._e(),(_vm.person.number && _vm.hasSecondLineSlot)?_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v(" "+_vm._s(_vm.person.number)+" ")]):_vm._e(),(_vm.isActive)?_c('div',{staticClass:"text-xs font-bold text-primary uppercase mx-1"},[_vm._v(" "+_vm._s(_vm.$t('active'))+" ")]):_vm._e()],1),(!_vm.hasSecondLineSlot)?_c('div',{staticClass:"text-xs text-gray-500 font-normal flex gap-3 items-center"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.person.email)+" ")]),(_vm.person.number)?_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v(" "+_vm._s(_vm.person.number)+" ")]):_vm._e()],1):_vm._e(),_vm._t("secondline")],2),_c('div',{staticClass:"flex-1"}),(_vm.hasOptionsSlot)?_c('div',{staticClass:"flex justify-end absolute top-0 right-3 bottom-0 items-center"},[_vm._t("options")],2):_vm._e()],1)]):_c('div',{staticClass:"relative w-full flex items-center",class:[
      { 'rounded-lg hover:bg-gray-200 hover:bg-opacity-50': !_vm.noStyle && (_vm.selectable || _vm.clickable) },
      _vm.customClass
    ]},[_c('div',{staticClass:"fw-row-checkbox-inside flex flex-1 gap-1 relative justify-between group items-center",class:{
        'pr-4': !_vm.selectable && _vm.view === 'row' && !_vm.noStyle,
        'p-2': _vm.paddingless === false,
        'opacity-50': _vm.rejected,
        'cursor-pointer': _vm.clickable || _vm.selectable
      },on:{"click":function($event){return _vm.processClick()}}},[(_vm.rejected)?_c('div',{staticClass:"absolute border-b border-gray-700 top-1/2 h-2 -mt-1.5 left-2 right-2"}):_vm._e(),_vm._t("leading"),(_vm.showAvatar)?_c('fw-avatar',{staticClass:"mr-1.5 flex-shrink-0",attrs:{"size":_vm.size,"user":_vm.person}}):_vm._e(),_c('div',{staticClass:"text-left leading-5"},[_c('div',{staticClass:"flex gap-2"},[_c('v-clamp',{staticClass:"font-medium",class:{ 'text-sm': _vm.isXs },attrs:{"autoresize":"","max-lines":_vm.nameMaxLines}},[_vm._v(" "+_vm._s(_vm.getPersonName())+" ")]),(_vm.showShortAndFullname)?_c('v-clamp',{staticClass:"text-gray-500 text-xs hidden md:block",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.person.display_name)+" ")]):_vm._e(),(_vm.isActive)?_c('div',{staticClass:"text-xs font-bold text-primary uppercase mx-1"},[_vm._v(" "+_vm._s(_vm.$t('active'))+" ")]):_vm._e(),(_vm.itsYou)?_c('div',[_c('fw-icon-start-smile-solid',{staticClass:"w-4 h-4 text-primary"})],1):_vm._e()],1),(!_vm.hasSecondLineSlot)?_c('div',{staticClass:"text-gray-500 font-normal flex gap-3 items-center break-all",class:{ 'text-sm': !_vm.isXs, 'text-xs': _vm.isXs }},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.person.email)+" ")]),(_vm.person.number)?_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v(" "+_vm._s(_vm.person.number)+" ")]):_vm._e()],1):_vm._e(),_vm._t("secondline")],2),_c('div',{staticClass:"flex-1"})],2),(_vm.hasOptionsSlot)?_c('div',{staticClass:"flex items-center mx-2"},[_vm._t("options")],2):_vm._e()]),_vm._t("modals")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }