<template>
  <div class="relative bulk-import-users p-5" :class="customClass">
    <div v-if="processing" class="flex flex-col gap-3 h-96">
      <div>
        <div class="text-lg font-bold">{{ title }}</div>
      </div>
      <div class="text-center text-gray-500 flex-1 flex flex-col justify-center items-center gap-5">
        <fw-icon-user-search class="h-12 text-gray-500"></fw-icon-user-search>
        {{ $tc('processingLines', numberOfLines) }}...
      </div>
      <div class="flex gap-3 mt-2">
        <div class="flex-1"></div>
        <fw-button :type="'link-muted'" disabled>Anterior</fw-button>
        <fw-button :type="'primary'" wider disabled :loading="processing">A carregar</fw-button>
      </div>
    </div>
    <div v-else-if="page == 0" class="flex flex-col gap-3 h-96">
      <div v-if="showHeader">
        <div class="text-lg font-bold">{{ title }}</div>
        <div class="text-sm">
          {{ instructions }}
        </div>
      </div>

      <b-input v-model="bulkusers" class="flex-1" type="textarea"></b-input>
      <div v-if="bulkusers.length && numberOfLines > 0" class="text-gray-400 text-sm">
        {{ $tc('numberOfLines', numberOfLines) }}
      </div>

      <div class="flex gap-3 mt-2">
        <div class="flex-1"></div>
        <fw-button :type="'link-muted'" @click.native="$emit('close')">Cancelar</fw-button>
        <fw-button :type="'primary'" wider :disabled="bulkusers.length == 0" @click.native="processUsers()"
          >Seguinte</fw-button
        >
      </div>
    </div>
    <div v-else-if="page == 1" class="flex flex-col gap-3">
      <div class="text-lg font-bold">{{ title }}</div>

      <fw-panel class="flex-1" :title="'Resultados'">
        <b-collapse
          :open="true"
          aria-id="usersFound"
          animation="slide"
          class="bg-white rounded-xl px-3 pt-2 pb-3 flex flex-col gap-2 mb-2"
        >
          <template #trigger="props">
            <div
              class="inline-flex items-center justify-between w-full gap-3"
              role="button"
              aria-controls="usersFound"
              :aria-expanded="props.open"
            >
              <div class="font-semibold">Utilizadores encontrados ({{ results.length }})</div>

              <div class="w-5 h-5">
                <fw-icon-chevron-up v-if="props.open" class="w-5 h-5" />
                <fw-icon-chevron-down v-else class="w-5 h-5 " />
              </div>
            </div>
          </template>
          <div class="w-full flex flex-col gap-2 rounded-lg overflow-y-auto" style="max-height: 400px">
            <div class="flex-1 -mb-1">
              <b-field>
                <b-checkbox size="is-small" :value="allResultsSelected" @input="toggleSelectAll('results', $event)"
                  >Selecionar todos</b-checkbox
                >
              </b-field>
            </div>
            <RecycleScroller
              v-if="results.length"
              v-slot="{ item, index }"
              :items="results"
              :item-size="55"
              :buffer="50"
              key-field="key"
            >
              <Person
                :key="item.key"
                :class="{
                  'border-b border-gray-100': index !== results.length - 1
                }"
                :person="item"
                :checked="selected.includes(item.key)"
                :selectable="true"
                :clickable="false"
                @selected="selectPerson(item.key, $event)"
              >
              </Person>
            </RecycleScroller>
            <div v-else class="text-center py-20 text-gray-500">Nenhum utilizador encontrado</div>
          </div>
        </b-collapse>

        <b-collapse
          v-if="notfound.length"
          :open="true"
          aria-id="usersNotFound"
          animation="slide"
          class="bg-white rounded-xl px-3 pt-2 pb-3 flex flex-col gap-2 mb-2"
        >
          <template #trigger="props">
            <div
              class="inline-flex items-center justify-between w-full gap-3"
              role="button"
              aria-controls="usersNotFound"
              :aria-expanded="props.open"
            >
              <div class="font-semibold">Utilizadores não encontrados ({{ notfound.length }})</div>

              <div class="w-5 h-5">
                <fw-icon-chevron-up v-if="props.open" class="w-5 h-5" />
                <fw-icon-chevron-down v-else class="w-5 h-5 " />
              </div>
            </div>
          </template>
          <div class="w-full flex flex-col gap-2 rounded-lg overflow-y-auto" style="max-height: 400px">
            <div class="flex-1 -mb-1">
              <b-field>
                <b-checkbox size="is-small" :value="allNotFoundSelected" @input="toggleSelectAll('notfound', $event)"
                  >Selecionar todos</b-checkbox
                >
              </b-field>
            </div>
            <RecycleScroller
              v-if="notfound.length"
              v-slot="{ item, index }"
              :items="notfound"
              :item-size="55"
              :buffer="50"
            >
              <Person
                :key="item"
                :class="{
                  'border-b border-gray-100': index !== notfound.length - 1
                }"
                :checked="selected.includes(item)"
                :person="{ email: item, full_name: parsedUsers[item] }"
                :selectable="true"
                :clickable="false"
                @selected="selectPerson(item, $event)"
              >
              </Person>
            </RecycleScroller>
          </div>
        </b-collapse>

        <fw-panel-info v-if="hasUsersWithoutName" type="orange" icon>Há utilizadores sem nome associados</fw-panel-info>
      </fw-panel>

      <fw-panel-info label="Data (raw)" class="my-5">
        <json-viewer
          :value="{
            bulkusers,
            parsedUsers,
            page,
            results,
            notfound,
            selected,
            allResultsSelected,
            allNotFoundSelected
          }"
        ></json-viewer>
      </fw-panel-info>

      <div class="flex gap-3 mt-2">
        <div class="flex-1"></div>
        <fw-button :type="'link-muted'" @click.native="page = 0">Anterior</fw-button>
        <fw-button :type="'primary'" wider :disabled="selected.length == 0" @click.native="confirmSelection"
          >Importar <span v-if="selected.length" class="text-xs ml-1">({{ selected.length }})</span></fw-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import { RecycleScroller } from 'vue-virtual-scroller'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'

export default {
  name: 'ModalImportBulkUsers',
  components: { RecycleScroller, Person },
  props: {
    context: Object,
    searchEngine: {
      type: Function,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Importar utilizadores'
    },
    instructions: {
      type: String,
      default:
        'Insira um número de estudante / funcionário ou email institucional / conta externa por linha (email,nome):'
    },
    customClass: {
      type: String
    },
    invitePeople: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bulkusers: '',
      processing: false,
      page: 0,
      results: [],
      notfound: [],
      selected: [],
      timer: null
    }
  },
  computed: {
    linesUsers() {
      return this.bulkusers.split(/\r\n|\r|\n/)
    },

    numberOfLines() {
      return this.linesUsers.length
    },

    hasUsersWithoutName() {
      if (!this.notfound.length) return false

      return this.notfound.some(user => !this.parsedUsers[user])
    },

    parsedUsers() {
      if (this.bulkusers.length == 0) return {}
      const response = {}
      for (const lineUser of this.linesUsers) {
        const [email, name] = lineUser.split(',')
        response[email] = name
      }

      console.log('parsedUsers :>> ', response)

      return response
    },

    allResultsSelected() {
      return this.results.every(el => this.selected.includes(el.key))
    },

    allNotFoundSelected() {
      return this.notfound.every(el => this.selected.includes(el))
    }
  },
  methods: {
    selectPerson(key, selectedValue) {
      console.log('selectPerson', { key, selectedValue })
      let i = this.selected.findIndex(el => el == key)
      if (i >= 0 && !selectedValue) {
        this.selected.splice(i, 1)
      }
      if (selectedValue && i == -1) {
        this.selected.push(key)
      }
    },
    confirmSelection() {
      if (this.selected.length > 0) {
        this.$emit('import', {
          users: this.results.filter(el => this.selected.includes(el.key)),
          invites: this.notfound
            .filter(el => this.selected.includes(el))
            .map(el => ({ email: el, name: this.parsedUsers[el] ?? el.split('@')[0] }))
        })
      }
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    async processUsers() {
      if (this.bulkusers.length == 0) return
      let query = Object.keys(this.parsedUsers).join(',')
      this.processing = true
      try {
        let result
        if (this.searchEngine != null) {
          result = await this.searchEngine(query)
        } else {
          result = await FormServices.bulkSearch(this.context.key, query)
        }
        console.log(result)
        if (this.timer != null) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          if (result.data.data) {
            this.results = result.data.data
            this.notfound = result.data.not_found
          } else {
            this.results = result.data
            this.notfound = result.not_found
          }
          this.selected = this.results.map(r => r.key)
          this.page = 1
          this.processing = false
        }, 1000)
      } catch (e) {
        console.error(e)
        this.processing = false
        this.showError('Ocorreu um erro ao encontrar os utilizadores')
      }
    },

    toggleSelectAll(type, checked) {
      console.log('toggleSelectAll', { type, checked })

      if (type == 'results') {
        const resultKeys = this.results.map(r => r.key)
        if (checked === true) {
          for (const key of resultKeys) {
            this.selected.push(key)
          }
        } else {
          this.selected = this.selected.filter(s => !resultKeys.includes(s))
        }
      } else if (type == 'notfound') {
        if (checked === true) {
          for (const item of this.notfound) {
            this.selected.push(item)
          }
        } else {
          this.selected = this.selected.filter(s => !this.notfound.includes(s))
        }
      }
    }
  }
}
</script>

<style>
.bulk-import-users .textarea {
  @apply h-full;
}
</style>

<i18n lang="json">
{
	"en": {
		"numberOfLines": "{count} line | {count} lines",
		"processingLines": "Processing {count} line | Processing {count} lines"
	},
	"pt": {
		"numberOfLines": "{count} linha | {count} linhas",
		"processingLines": "A processar {count} linha | A processar {count} linhas"
	}
}
</i18n>
