<template>
  <fw-layout
    :header="false"
    full
    :loading="initialLoading"
    :mobile-ready="true"
    loading-icon="live"
    loading-title="UC DigitalPad"
    class="digitalpad"
    :class="{
      font15x: fontSize > 1.25 && fontSize < 1.8,
      font20x: fontSize >= 1.8
    }"
    :style="{ fontSize: calculatedFontSize }"
  >
    <template #main>
      <div class="h-full overflow-auto w-[32rem] bg-gray-100 p-10 flex flex-col gap-20">
        <div class="flex gap-5 justify-between">
          <div class="flex flex-col gap-0.5">
            <div><AppLogo /></div>
            <div class="text-xs opacity-50">{{ appVersion }}</div>
          </div>
          <div class="flex flex-col justify-end items-end -mt-1">
            <div class="text-2xl font-semibold flex items-center w-[6.5rem]">
              <div class="hour w-8 text-left">{{ clock.hours || '00' }}</div>
              <div class="text-gray-500">:</div>
              <div class="min w-8 text-left">{{ clock.minutes || '00' }}</div>
              <div class="text-gray-400">:</div>
              <div class="text-gray-400 w-9 text-left">{{ clock.seconds || '00' }}</div>
            </div>
            <div class="text-sm font-medium text-gray-400 w-28 text-left pl-2">
              {{ today | formatDate }}
            </div>
          </div>
        </div>
        <div class="flex-1 flex flex-col gap-20">
          <div class="flex flex-col gap-10 max-w-xl mx-auto">
            <div class="flex items-center justify-center">
              <BlockUCId :user="user" />
            </div>
            <div class="flex flex-col gap-1 items-center justify-center">
              <h2 class="text-3xl font-semibold text-center max-w-sm mx-auto mb-5">
                <v-clamp autoresize :max-lines="3">{{ $t('hello') }}, {{ user.name }}.</v-clamp>
              </h2>
              <div v-if="user.number" class="text-2xl font-medium">{{ user.number }}</div>
              <div v-if="user.email" class="text-lg text-gray-500">{{ user.email }}</div>
            </div>
          </div>
        </div>
        <div>
          <fw-button type="danger" size="lg" expanded @click.native="logout">Terminar sessão</fw-button>
        </div>
      </div>
      <div class="h-full flex-1 flex flex-col adapt-text justify-center items-center relative">
        <div class="flex absolute top-4 right-5 justify-start">
          <HeaderLanguagePanel />
        </div>
        <div class="mx-auto max-w-4xl">
          <ModalEnterExam :show="showModalExam" @close="openExamModal(false)" />
        </div>
      </div>
      <!-- Accessibility -->
      <div class="hidden fixed select-none sm:flex bottom-10 right-10 justify-end gap-2">
        <fw-button type="xlight" size="sm" class="w-12 h-12" @click.native="changeFontSize('less')">A</fw-button>
        <fw-button type="xlight" size="sm" class="text-2xl w-12 h-12" @click.native="changeFontSize('more')"
          >A</fw-button
        >
      </div>
    </template>
  </fw-layout>
</template>

<script>
// import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import AppLogo from '@/components/AppLogo'
import BlockUCId from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUCIdQRCode'
import ModalEnterExam from '../components/modals/ModalEnterExam'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import AcademicUtilities from '@/fw-modules/fw-core-vue/academic/services/utilities'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'

export default {
  components: {
    AppLogo,
    ModalEnterExam,
    HeaderLanguagePanel,
    BlockUCId
  },

  data() {
    return {
      debugActive: localStorage.getItem('fw-exam-debug') == 'true',
      activatingDebug: false,
      debugModeTimer: null,
      startPos: null,
      startPage: null,
      page: 0,
      showModalExam: false,
      initialLoading: true,
      loading: false,
      classesLoaded: false,

      // Helper
      users: {},

      // Classes
      activeSessions: [],
      todaySessions: [],
      thisWeekSessions: [],
      nextWeekSessions: [],
      nextSessionInterval: null,

      // Clock and date
      today: new Date(),
      clock: {
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      clockInterval: null,
      fontSize: 1,
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY
    }
  },

  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development'
    },
    calculatedFontSize() {
      return this.fontSize * 100 + '%'
    },
    isDigitalPad() {
      return process.env.VUE_APP_KEY == 'ucdigitalpad'
    },
    user() {
      return this.$store.getters.getUser
    },
    meeting() {
      if (this.user && this.user.meeting) return this.user.meeting
      else return null
    },
    logoutUrl() {
      // return FwEnvConfig.appUrlUCId + '/logout'
      return '/logout'
    },
    // New notifications and chat messages
    unreadNotifications() {
      return this.$store.getters.getUnreadNotificationsCount
    },
    unreadChatMessages() {
      return this.$store.getters.getUnreadChatMessagesCount
    },

    // Checks / validations
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher'))
    },
    isStudent() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('student'))
    },
    hasClasses() {
      return (
        this.activeSessions.length ||
        this.todaySessions.length ||
        this.thisWeekSessions.length ||
        this.nextWeekSessions.length
      )
    }
  },

  watch: {
    user() {
      console.log('user changed')
      this.start()
    }
  },

  created() {
    if (localStorage.getItem('pin_instance_key')) {
      let instance_key = localStorage.getItem('pin_instance_key')
      //remove instance key from local storage
      localStorage.removeItem('pin_instance_key')
      this.$router.push({ name: 'exams-examination', params: { key: instance_key } })
    }
  },

  mounted() {
    this.$store.dispatch('setUser')
    this.openExamModal(true)

    this.setClock()
    this.start()
    this.loadFontSize()
    setTimeout(() => {
      this.initialLoading = false
    }, 1500)
  },

  beforeDestroy() {
    if (this.nextSessionInterval) clearInterval(this.nextSessionInterval)
    if (this.clockInterval) clearInterval(this.clockInterval)
  },

  methods: {
    debugMode(action) {
      if (!this.isDev) return
      if (action == 'start') {
        this.activatingDebug = true
        this.debugModeTimer = setTimeout(() => {
          this.activatingDebug = false
          if (this.debugActive) {
            localStorage.removeItem('fw-exam-debug')
            this.debugActive = false
          } else {
            localStorage.setItem('fw-exam-debug', 'true')
            this.debugActive = true
          }
          //refresh page
          window.location.reload()
        }, 3000)
      } else {
        clearTimeout(this.debugModeTimer)
        this.activatingDebug = false
      }
    },
    dragging(e) {
      if (this.startPos) {
        //make compatible with touch
        let currentY = e.touches ? e.touches[0].clientY : e.clientY
        //let x = e.clientX - this.startPos.x
        let y = currentY - this.startPos
        //this.$el.style.transform = `translate(${x}px, ${y}px)`
        //page from 0 to 6, 0 is the first page and each page is 8.5rem
        let newPage = this.startPage - Math.round(y / 100)
        this.page = Math.min(6, Math.max(0, newPage))
      }
    },
    startDrag(e) {
      console.log('start drag', e)
      this.startPage = this.page
      let currentY = e.touches ? e.touches[0].clientY : e.clientY
      this.startPos = currentY
    },
    endDrag(e) {
      console.log('end drag', e)
      this.startPos = null
      this.startPage = null
    },
    next() {
      if (this.page < 6) {
        this.page++
      }
    },
    openExamModal(show = true) {
      this.showModalExam = show
    },
    async loadFontSize() {
      if (this.isDigitalPad) {
        let fontSize = await ServiceSettings.getSetting('tabletFontSize')
        if (fontSize && fontSize.value) {
          this.fontSize = parseFloat(fontSize.value)
        }
      }
    },
    async changeFontSize(option) {
      if (option == 'more' && this.fontSize < 2) {
        this.fontSize += 0.25
      } else if (option == 'less' && this.fontSize > 1) {
        this.fontSize -= 0.25
      }
      //TODO: Save to tablet profile (if we have one)
      if (this.isDigitalPad) {
        await ServiceSettings.setSetting('tabletFontSize', this.fontSize)
      }
    },
    start() {
      if (this.classesLoaded) return
    },
    async getNextClassesSessions() {
      let sessions = []
      if (this.isTeacher) {
        sessions = await ServiceAcademic.getNextTeacherSessions()
      } else if (this.isStudent) {
        sessions = await ServiceAcademic.getNextStudentSessions()
      }
      if (sessions) {
        AcademicUtilities.buildSessionDates(
          sessions,
          this.activeSessions,
          this.todaySessions,
          this.thisWeekSessions,
          this.nextWeekSessions
        )
        this.nextSessionInterval = setInterval(this.checkSessionDates, 30 * 1000)
      }
    },
    spaceAndBuildingText(session) {
      if (session.space && session.building) {
        return session.space.title + ' ' + session.building.title
      } else if (session.space) {
        return session.space.title
      } else if (session.building) {
        return session.building.title
      } else {
        return ''
      }
    },
    checkSessionDates() {
      AcademicUtilities.checkSessionDates(
        this.activeSessions,
        this.todaySessions,
        this.thisWeekSessions,
        this.nextWeekSessions
      )
    },

    // Logout
    logout() {
      window.location.href = this.logoutUrl
    },

    // Clock
    setClock() {
      this.clockInterval = setInterval(() => {
        const date = new Date()
        this.clock.hours = date.getHours()
        this.clock.minutes = this.checkSingleDigit(date.getMinutes())
        this.clock.seconds = this.checkSingleDigit(date.getSeconds())
      }, 1000)
    },
    checkSingleDigit(digit) {
      return ('0' + digit).slice(-2)
    }
  }
}
</script>

<style lang="scss">
.digitalpad .empty-tab {
  @apply h-full flex items-center justify-center text-center text-gray-500 rounded-2xl flex-col gap-5;
}
.digitalpad.font15x .adapt-text .text-sm {
  @apply text-base;
}
.digitalpad.font15x .adapt-text .text-base {
  @apply text-lg;
}
.digitalpad.font15x .adapt-text .text-lg {
  @apply text-xl;
}
.digitalpad.font15x .adapt-text .text-xl {
  @apply text-2xl;
}
.digitalpad.font15x .adapt-text .text-2xl {
  @apply text-3xl;
}

.digitalpad.font15x .adapt-text .leading-5 {
  @apply leading-6;
}
.digitalpad.font15x .adapt-text .leading-6 {
  @apply leading-7;
}

.digitalpad.font20x .adapt-text .text-sm {
  @apply text-xl;
}

.digitalpad.font20x .adapt-text .text-base {
  @apply text-2xl;
}

.digitalpad.font20x .adapt-text .text-lg {
  @apply text-3xl;
}
.digitalpad.font20x .adapt-text .text-xl {
  @apply text-4xl;
}
.digitalpad.font20x .adapt-text .text-2xl {
  @apply text-5xl;
}

.digitalpad.font20x .adapt-text .leading-5 {
  @apply leading-7;
}
.digitalpad.font20x .adapt-text .leading-6 {
  @apply leading-8;
}
</style>

<i18n>
{
  "pt": {
    "hello": "Olá",
    "welcome": "Bem-vindo ao UC Digital Pad!",
    "instructions": "Instruções para o exame:",
    "step1": "Insira o código do exame.",
    "step2": "Aceite os termos de utilização.",
    "step3": "Aguarde que o exame comece.",
    "step4": "Mostre o que sabe respondendo ao exame. Boa sorte!",
    "step5": "Quando terminar, clique em 'Entregar exame'.",
    "step5b": "Pst.. Se não estiver nos seus dias mais brilhantes pode desistir a qualquer momento clicando em 'Desistir do exame'.",
    "step6": "Devolva o tablet ao vigilante mais próximo."
  },
  "en": {
    "hello": "Hello",
    "welcome": "Welcome to UC Digital Pad!",
    "instructions": "Instructions for the exam:",
    "step1": "Enter the code for the exam.",
    "step2": "Accept the terms of use.",
    "step3": "Wait for the exam to start.",
    "step4": "Show what you know by answering the exam. Good luck!",
    "step5": "When you finish, click on 'Submit exam'.",
    "step5b": "Pst.. If you're not having your brightest day, you can quit at any time by clicking on 'Quit exam'.",
    "step6": "Return the tablet to the nearest invigilator."
  }
}
</i18n>
