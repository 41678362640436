<template>
  <div class="chat-files group overflow-visible" :data-key="message.key">
    <div v-if="attachedFiles.length">
      <div
        v-for="(file, f) in attachedFiles"
        :key="file.key"
        class="group flex gap-2.5 pr-3 items-center cursor-pointer"
        :class="{
          'pb-2': f < attachedFiles.length - 1,
          'pl-1': !paddingless
        }"
        @click="$emit('preview-file', file)"
      >
        <div class="h-10 w-10 rounded-md overflow-hidden flex-shrink-0 bg-black/10">
          <div
            v-if="file.type === 'image'"
            class="h-full w-full"
            :style="{
              backgroundImage: `url(${getImageViewUrl(file)})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }"
          ></div>
          <div
            v-else-if="file.type === 'video' || file.type === 'audio'"
            class="h-full w-full flex items-center justify-center"
          >
            <fw-icon-play-circle class="w-5 h-5 opacity-50" />
          </div>
          <div v-else class="h-full w-full flex items-center justify-center">
            <fw-icon-file class="w-5 h-5  opacity-60" />
          </div>
        </div>
        <div class="flex-1 text-sm font-medium">
          <v-clamp autoresize :max-lines="1">
            {{ file.filename }}
          </v-clamp>
          <div class="flex gap-2 text-xs opacity-60">
            {{ message.created_date | formatDateTime }}
          </div>
        </div>
        <a
          v-if="allowDownloads"
          @click.stop
          target="_blank"
          :href="getFileUrl(file)"
          class="font-bold text-sm inline-flex"
        >
          <fw-icon-download class="w-5 h-5" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    message: Object,
    chatType: String,
    chatKey: String,
    user: Object,
    parentUser: Object,
    canDeleteAll: Boolean,
    allowDownloads: {
      type: Boolean,
      default: true
    },
    onlyOthers: {
      type: Boolean,
      default: false
    },
    paddingless: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    attachedFiles() {
      return this.onlyOthers
        ? this.message.files.filter(file => file.type != 'image' && file.type != 'video')
        : this.message.files
    },
    loggedUser() {
      return this.$store.getters.getUser
    }
  },

  methods: {
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file)
    },
    getFileViewUrl(file, download = false) {
      return ServiceStorage.getFileViewUrl(file, download)
    },
    getImageViewUrl(file) {
      return ServiceStorage.getImageUrl(file, '200')
    },
    recordingsPosterURL(recording) {
      return utils.recordingsPosterURL(recording)
    }
  }
}
</script>

<style lang="scss">
.message-attention {
  @apply border-transparent;
}
.show-message {
  .message-attention {
    @apply border-primary;
  }
}
.chat-message {
  .text-primary {
    * {
      @apply text-primary text-base;
    }
  }
  pre {
    @apply bg-opacity-50 bg-white p-4 rounded-xl;
  }
  .fix-links-overflow {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
  }
}
.chat-message.isSelf {
  strong {
    @apply text-white;
  }
}
.chat-message.isNotSelf {
  strong {
    @apply text-gray-800;
  }
}
</style>
