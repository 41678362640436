<template>
  <div
    class="progress-icon flex gap-x-2 items-center"
    :class="{ 'flex-col': percentagePos === 'top' || percentagePos === 'bottom' }"
  >
    <div
      v-if="showPercentage && (percentagePos === 'top' || percentagePos === 'left')"
      class="w-full font-semibold rounded-full"
      :class="{
        'text-right': align === 'right',
        'text-left': align === 'left',
        'text-white': type == 'light',
        'text-gray-600': type == 'regular'
      }"
    >
      {{ progress }}%
    </div>
    <div
      class="h-4 rounded-full flex-1 bg-opacity-20 w-full flex-shrink-0 overflow-hidden"
      :class="{
        'text-white bg-white bg-opacity-10': type == 'light',
        'text-gray-600 bg-gray-500 bg-opacity-20': type == 'regular'
      }"
    >
      <div class="h-4 rounded-full transition-all" :class="[`bg-${barColor}`]" :style="{ width: progress + '%' }"></div>
    </div>
    <div
      v-if="showPercentage && (percentagePos === 'bottom' || percentagePos === 'right')"
      class="text-sm font-semibold"
      :class="{
        'text-right': align === 'right',
        'text-left': align === 'left',
        'w-full': percentagePos === 'bottom',
        'text-white opacity-80 font-medium': type == 'light',
        'text-gray-500': type == 'regular'
      }"
    >
      {{ progress }}%
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressIcon',
  props: {
    type: {
      type: String,
      default: 'regular'
    },
    barColor: {
      type: String,
      default: 'primary'
    },
    showPercentage: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    },
    align: {
      type: String,
      default: 'right'
    },
    percentagePos: {
      type: String,
      default: 'top'
    }
  }
}
</script>

<style scoped></style>
