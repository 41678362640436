<template>
  <div class="people-list flex flex-col">
    <slot name="header"></slot>
    <div v-if="searchable || invitePeople" class="flex justify-between items-center">
      <div
        class="flex w-full items-center bg-gray-200 bg-opacity-50 mb-5 outline-none focus:ring-2 ring-primary ring-opacity-30 rounded-xl"
      >
        <div v-if="searchable" class="flex w-full items-center px-3 py-1.5">
          <div class="w-6 ml-1">
            <fw-icon-loading v-if="searching" class="w-6 h-6" />
            <fw-icon-search v-else class="w-6 h-6 opacity-50" />
          </div>
          <slot name="prefix"></slot>
          <input
            ref="searchIdUser"
            v-model="$v.searchQuery.$model"
            class="outline-none font-semibold flex-1 px-1 py-0.5 bg-transparent ml-1"
            type="text"
            :placeholder="`${$t('searchText')}...`"
            @keypress.enter="searchUsers()"
            @input="searchQueryChanged()"
          />
          <fw-button type="transparent-primary" class="ml-3" @click.native="searchUsers">{{
            $t('searchText')
          }}</fw-button>
        </div>

        <fw-button v-if="invitePeople" type="xlight" icon="user-add" @click.native="$emit('invite', null)">
          {{ $t('invite') }}
        </fw-button>
      </div>
    </div>

    <div
      style="height: 275px"
      class="overflow-auto"
      :class="{ 'border-b border-gray-200': !searching && searchedPeople && searchedPeople.length }"
    >
      <LoadingPlaceholder v-if="searching" class="px-2 py-2" />

      <fw-panel
        v-if="!searching && searchedPeople && searchedPeople.length"
        :title="$t('results')"
        :counter="searchedPeople.length"
      >
        <RecycleScroller
          v-slot="{ item, index }"
          :items="searchedPeople"
          :item-size="48"
          :buffer="50"
          :key-field="idKey"
          class="h-full"
        >
          <Person
            :key="index"
            :person="item"
            :no-style="noStyle"
            :class="{ 'mx-5': noPadding === false }"
            :selectable="selectable"
            :clickable="clickable"
            :disabled="!canSelect && !tmpSelected.includes(item.key)"
            :checked="tmpSelected.includes(item.key)"
            @selected="selectingPerson(item, ...arguments)"
            @clicked="selectingPerson(item, ...arguments)"
          >
            <template v-if="showPersonAttributes.length > 0 || item.active === false" #options>
              <div v-if="item.active === false" class="inline-flex gap-2 text-gray-500 text-xs items-center">
                {{ $t('pendingInvite') }}
              </div>
              <div
                v-for="(attr, a) in showPersonAttributes"
                :key="'attr_' + a"
                :class="{ 'text-opacity-60': item.rejected }"
              >
                {{ item[attr] ? item[attr] : '--' }}
              </div>
            </template>
          </Person>
        </RecycleScroller>
        <fw-tip v-if="searchable" class="items-center justify-center">
          <div class="text-center" v-html="$t('limitedNumber')"></div>
        </fw-tip>
      </fw-panel>

      <fw-panel-info v-else-if="!searching" empty clean class="h-full items-center justify-center flex">
        <p>{{ $t('noDataFound') }}.</p>
        <fw-button v-if="showInviteBtn" type="primary" icon="user-add" @click.native="$emit('invite', searchQuery)">
          {{ $t('invite') }} {{ searchQuery }}
        </fw-button>
      </fw-panel-info>
    </div>

    <slot name="after"></slot>
  </div>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'
import { RecycleScroller } from 'vue-virtual-scroller'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'PeopleList',
  components: {
    Person,
    RecycleScroller,
    LoadingPlaceholder
  },
  props: {
    idKey: {
      type: String,
      default: 'key'
    },
    selectable: {
      type: Boolean,
      default: false
    },
    maxSelectable: {
      type: Number
    },
    clickable: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    noStyle: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    invitePeople: {
      type: Boolean,
      default: false
    },
    showPersonAttributes: {
      type: Array,
      default: function() {
        return []
      }
    },
    notAlowedUsers: {
      type: Array,
      default: function() {
        return []
      }
    },
    queryFilters: {
      type: Array,
      default: function() {
        return []
      }
    },
    injectPayload: {
      type: Object,
      default: () => {}
    },
    endpoint: {
      type: Function
    },
    usersList: {
      // defined list of users to show
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      searchQuery: '',
      tmpSelected: [], //keys
      debounceTimer: null,
      searchedPeople: [],
      searching: false,
      totalLimit: 100,
      totalPages: 1,
      controller: new AbortController(),
      searched: false
    }
  },

  computed: {
    canSelect() {
      if (this.maxSelectable || this.maxSelectable === 0) {
        return this.selectable && this.tmpSelected.length < this.maxSelectable
      }
      return this.selectable
    },

    showInviteBtn() {
      if (!this.invitePeople) return false

      if (this.searching || !this.searchable || !this.searched) return false
      if (this.$v.$invalid) return false

      return true
    }
  },

  validations: {
    searchQuery: {
      required,
      email,
      min: minLength(5),
      max: maxLength(255)
    }
  },

  mounted() {
    if (this.usersList && this.usersList.length) {
      this.searchedPeople = this.usersList
    }
    this.$nextTick(() => {
      if (this.searchable) this.$refs.searchIdUser.focus()
    })
  },

  methods: {
    debounce(func, timeout = 300) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer)
      }
      this.debounceTimer = setTimeout(() => {
        func.apply()
      }, timeout)
    },

    async searchUsers() {
      if (this.searchQuery.length > 0) {
        try {
          this.searching = true
          const query = {
            query: this.searchQuery,
            limit: this.totalLimit,
            'exclude[]': this.notAlowedUsers,
            ...this.injectPayload
          }

          if (this.queryFilters.length > 0) {
            query['filters'] = this.queryFilters.join(',')
          }
          const resultUsers = await this.endpoint(query)
          // console.log('Search result ==> ', resultUsers)
          if (resultUsers.users) {
            this.searchedPeople = resultUsers.data = resultUsers.users
            this.totalPages = resultUsers.pagination.total_pages
          } else if (resultUsers.data.data) {
            this.searchedPeople = resultUsers.data = resultUsers.data.data
            this.totalPages = resultUsers.data.total_pages
          } else {
            this.totalPages = resultUsers.total_pages
            this.searchedPeople = resultUsers.data
          }
          this.searched = true
          this.searching = false
        } catch (error) {
          console.error('Search went wrong', error)
        }
      }
    },

    selectingPerson(person, selected) {
      if (this.selectable) {
        const index = this.tmpSelected.findIndex(i => i == person.key) // === person.key
        if (selected && index === -1) {
          this.tmpSelected.push(person.key)
        } else {
          this.tmpSelected.splice(index, 1)
        }
      } else {
        this.tmpSelected = [person.key]
      }

      const result = this.searchedPeople.filter(person => this.tmpSelected.includes(person[this.idKey]))

      if (this.selectable) {
        this.$emit('selected', result)
      } else {
        this.$emit('clicked', result)
      }
    },

    searchQueryChanged() {
      if (this.searched) this.searched = false
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "searchText": "Pesquisar",
    "noDataFound": "Sem resultados",
    "invite": "Convidar",
    "results": "Resultados",
    "pendingInvite": "Convite pendente",
    "limitedNumber": "Esta pesquisa apresenta um número limitado de resultados.<br />Defina melhor as palavras chaves para encontrar o registo que procura."
  },
  "en": {
    "searchText": "Search",
    "noDataFound": "No results found",
    "invite": "Invite",
    "results": "Results",
    "pendingInvite": "Pending Invitation",
    "limitedNumber": "This search shows a limited number of results.<br />Further define your keywords to find the record you are looking for."
  }
}
</i18n>
